.menu-item {
  text-align: center;
  display: inline-block;
  min-width: 8em;

  padding-top: 0.4em;
  padding-bottom: 0.4em;
  margin-left: 1em;

  background-color: rgba(255,255,255,0.5);
  box-shadow: 0.1em 0.1em 0.1em rgba(40,40,40,0.3);

  cursor: pointer;
  font-size: 1.25em;
}

.menu-item:hover {
  box-shadow: inset 0.1em 0.1em 0.1em rgba(40,40,40,0.3);
}

.active {
  box-shadow: inset 0.1em 0.1em 0.1em rgba(40,40,40,0.3);
  cursor: default;
}
