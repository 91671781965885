.main {
  margin: auto;
  height: calc(100% - 5em);
  background-color: rgba(255,255,255,0.8);
  width: 100%;
  box-shadow: 0.25em 0.25em 0.1em rgba(40,40,40,0.6);
  min-width: 500px;
}

@media (min-width: 720px) {
  .main {
    width: 90%;
    margin-top: 0.5em;
  }
}

@media (min-width: 900px) {
  .main {
    margin-top: 1em;
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .main {
    margin-top: 2em;
  }
}
