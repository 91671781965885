.panel {
  height: calc(100% - 7em);
  margin: 1em;
  padding: 1em;

  background-color: rgba(255,255,255,0.5);
  box-shadow: 0.1em 0.1em 0.1em rgba(40,40,40,0.3);

  // font-family: 'Lithos Black Bold';
  font-family: CCComicrazy;

}

.panel-text {
  font-size: 1em;
}

.panel-headline {
  font-size: 1.5em;
}
