.menu {
  padding-top: 1em;
  // font-family: 'Lithos Black Bold';
  font-family: CCComicrazy;
  height: 2.5em;
}

.menu-items-left {
  float: left;
}

.menu-items-right {
  float: right;
}
