.question {
  font-family: 'CCComicrazy';
  padding-top: 1.5em;
  font-size: 1.2em;
  color: #111;
}

.answer {
  font-family: 'CCComicrazy';
  padding-top: 0.75em;
  color: #111;
}

.answer a {
  text-decoration: underline;
}
